<template>
  <div class="view-home">
    <div class="banner-box">
      <el-carousel class="banner-list" :interval="3000" trigger="click">
        <el-carousel-item class="banner-item" v-for="item,index in bannerList" :key="index">
          <img class="w100 banner-img" :src="item.img" alt="" srcset="">
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="main-box1">
      <div class="box1-center flex-c">
        <div v-for="item,index in tabList" :key="index" class="box1-item">
          <div class="box1-item-title">
            <CountTo :startVal='0' :endVal='item.title*1' :duration='2000' />
            <span v-if="item.endTitle">{{item.endTitle}}</span>
          </div>
          <div class="box1-item-text">{{item.text}}</div>
        </div>
      </div>
    </div>
    <div class="about-us-box flex-s">
      <div class="left-box">
        About<br />
        Us
      </div>
      <div class="right-box">
        Chengdu Orecto Import and Export Trading Co.,Ltd (hereafter referred to as Orecto) is an enterprise specialized in excellent quality of PVC flooring.Our factory has been in the line for more than 34 years and has successfully
        stepped into international market with its best quality and innovative. Having accumulated much experience in these fields, we have gained good reputation for years of excellent quality, service and professional advice on customers
        requests.
        <div @click="$router.push('/About')" class="more-box flex-c point">
          <div class="common-more">
            More
          </div>
        </div>
      </div>
    </div>
    <div class="main-box2">
      <div class="box2-title common-title-font">
        Quality Is Our Life
      </div>
      <div class="box2-item-list">
        <div v-for="item,index in ourLifeList" :key="index" class="box2-item" :class="{'box2-item-width':index===1||index===4}">
          <div class="item-img">
            <img class="w100" :src="item.img" alt="" srcset="">
          </div>
          <div class="item-title">
            {{item.title}}
          </div>
          <div class="item-text">
            {{item.text}}
          </div>
        </div>
      </div>
    </div>
    <div class="main-box3 flex-c">
      <div class="box-3-title common-title-font">
        Our PVC Flooring Are safe for Elderly, Toddlers And Pets.
      </div>
    </div>
    <div class="main-box4">
      <div class="box4-block1 flex-b">
        <div class="left-text">
          Quality is our life, safety is our top priority, our vinyl flooring has been carefully crafted to ensure it meets stringent safety standards, with our vinyl flooring, you can enjoy peace of mind, knowing your well-being is
          protected. Our vinyl flooring has obtained certifications from those esteemed organizations: FloorSore, CE, SCS Global,ISO9001 etc. FloorScore is a certification program that ensures indoor air quality and verifies that our
          product meets low VOC emission standards.These certifications solidify our commitment to delivering a flooring product that meets and exceeds industry standards. ISO 9001 certification ensures that our manufacturing processes
          adhere to stringent quality management principles, guaranteeing consistency and excellence in every aspect of our vinyl flooring production.
        </div>
        <div class="right-img">
          <img class="w100" src="@/assets/images/all/pc-icon11.png" alt="" srcset="">
        </div>
      </div>

      <div class="box4-block3">
        <div class="home-bg2"></div>
        <div class="block3-top">
          <div v-for="item,index in topList" :key="index" class="top-item">
            <img class="w100" :src="item.img" alt="" srcset="">
          </div>
          <div class="common-icon-title block3-top-title">
            Product Display
          </div>
        </div>
        <div class="block3-bottom">
          <div v-for="item,index in bottomList" :key="index" class="bottom-item">
            <img class="w100" :src="item.img" alt="" srcset="">
          </div>
          <div class="more-box">
            <div @click="$router.push('/Product')" class="common-more point">
              More
            </div>
          </div>
        </div>
      </div>

      <div class="box4-block4 flex-b">
        <div v-for="item,index in productIconList" :key="index" class="block4-item">
          <div class="item-img flex-c">
            <img class="img" :src="item.img" alt="" srcset="">
          </div>
          <div class="item-title">
            {{item.title}}
          </div>
        </div>
      </div>

    </div>
    <div class="main-box5">
      <div class="box5-title flex-c">
        <div class="common-icon-title">
          Project
        </div>
      </div>

      <div v-if="finalCaseList.length" class="box5-banner">

        <!-- 轮播图 -->
        <div class="swiper swiper-box" id="swiper1">
          <div @click="changeImg('left')" class="left-arrow point">
          </div>
          <div @click="changeImg('right')" class="right-arrow point">
          </div>
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(item, index) in finalCaseList" :key="index">
              <img class="w100 h100 bookImg" :src="onlineUrl+item.images[0]" alt="" srcset="">
            </div>
          </div>
        </div>
      </div>
      <div class="more-box flex-c point">
        <div @click="$router.push('/Case')" class="common-more">
          more
        </div>
      </div>
    </div>
    <div class="main-box4">
      <div class="box4-block2">
        <div class="title-box flex-c">
          <div class="common-icon-title">
            User Reviews
          </div>
        </div>
        <div class="comment-box">
          <div class="home-bg1"></div>
          <div v-if="show" class="top-comment-box">
            <div v-for="item,index in finalCommentList" :key="index" class="comment-item flex-s" :class="{'comment-item-right':index===1}">
              <div class="header-img">
                <img class="w100" :src="item.img" alt="" srcset="">
              </div>
              <div class="comment-text">
                <div class="text flex-s">
                  {{item.content}}
                </div>
                <div class="star">
                  <img class="h100" :src="item.star" alt="" srcset="">
                </div>
              </div>
            </div>
          </div>
          <div class="page-box flex-e">
            <div class="pages flex-c ">
              <div @click="commentNext('down')" class="page-left point"></div>
              <div @click="commentNext('up')" class="page-right point"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main-box6 flex-s">
      <div class="home-bg3"></div>
      <div class="home-bg4"></div>
      <div class="left-title-box">
        <div class="common-icon-title">
          Contact Us
        </div>
      </div>
      <div class="right-form-box">
        <div class="div1 flex-b">
          <div class="input flex-s">
            <el-input v-model="formData.name" placeholder="Name"></el-input>
          </div>
          <div class="input flex-s">
            <el-input v-model="formData.email" placeholder="E-mail"></el-input>
          </div>
        </div>
        <div class="div2">
          <el-input type="textarea" placeholder="Message" v-model="formData.content">
          </el-input>
        </div>
        <div class="div3 flex-c">
          <div @click="submitForm" class="btn">
            Submit
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCaseListApi } from '@/network/api';
import { mapState } from 'vuex';
import { contactUsApi } from '@/network/api';
import Swiper from 'swiper';
import CountTo from 'vue-count-to';

export default {
  components: {
    CountTo
  },
  data() {
    return {
      tabList: [
        {
          title: '34',
          endTitle: '+',
          text: 'Years of Experience'
        },
        {
          title: '400',
          endTitle: '+',
          text: 'Projects'
        },
        {
          title: '45',
          endTitle: '+',
          text: '5-star User Reviews'
        },
      ],
      ourLifeList: [
        {
          img: require('@/assets/images/all/pc-icon901.png'),
          title: 'Certified Quality Material',
          text: 'Fully-certified material with industry grade testing'
        },
        {
          img: require('@/assets/images/all/pc-icon902.png'),
          title: 'After-Sales Service',
          text: 'Considerate After-Sales Customer Service'
        },
        {
          img: require('@/assets/images/all/pc-icon903.png'),
          title: 'Round-the-Clock Service',
          text: 'Orecto’s round-the-clock service support team is always on standby for breakdown maintenance'
        },
        {
          img: require('@/assets/images/all/pc-icon904.png'),
          title: 'Quality are strictly controlled',
          text: 'All materials are strictly controlled before entering the factory and strictly inspected before shipment'
        },
        {
          img: require('@/assets/images/all/pc-icon905.png'),
          title: 'Rich foreign trade experience',
          text: 'Almost 20 years of foreign trade experience we can give you best service'
        },
        {
          img: require('@/assets/images/all/pc-icon906.png'),
          title: '100% transparent consumption',
          text: 'No hidden fees, No surprises fees, 100% transparent'
        },
      ],
      commentList: [
        {
          img: require('@/assets/images/all/pc-comment-user1.png'),
          star: require('@/assets/images/all/pc-comment-star1.png'),
          content: 'The quality would like to say is really good since I booked those samples from three suppliers but finally chose Orecto, details treatment i am satisfied and tested quite good. Also thank you so much for Jacky’s assistance and expert advice'
        },
        {
          img: require('@/assets/images/all/pc-comment-user2.png'),
          star: require('@/assets/images/all/pc-comment-star2.png'),
          content: 'This is the third time we received SPC flooring from Orecto, stable quality is one of the most important factors we chose them, our customers are also very satisfied since most of them have gave us the return order'
        },
        {
          img: require('@/assets/images/all/pc-comment-user3.png'),
          star: require('@/assets/images/all/pc-comment-star1.png'),
          content: 'Here we would like to thank you for your timely delivery as usual to catch up our renovation season so that all the orders can be normally processed as scheduled, thank you so much'
        },
        {
          img: require('@/assets/images/all/pc-comment-user4.png'),
          star: require('@/assets/images/all/pc-comment-star1.png'),
          content: 'I would like to say, we’ve been in the line of building material industry for years, this year we converted to Orecto and received very good results should be the market demands and accumulated customers from the traditional floor industry'
        },
        {
          img: require('@/assets/images/all/pc-comment-user5.png'),
          star: require('@/assets/images/all/pc-comment-star2.png'),
          content: 'After fully experienced the new PVC flooring we decided to leave our evaluation; Compared to the traditional floor, we prefer to have this latest ones for it’s water-resistant, anti-resistant, and especially eco-friendly, easy installation and with fashionable effects but low cost'
        },
        {
          img: require('@/assets/images/all/pc-comment-user6.png'),
          star: require('@/assets/images/all/pc-comment-star1.png'),
          content: 'Really love my home now for comfortable feeling door with beautiful color, meanwhile we highly appreciate Christine’s patient advice on various type floor and finally chose the type suitable for me, we’ll recommend you to all my friends who need to change the floor'
        },
        {
          img: require('@/assets/images/all/pc-comment-user7.png'),
          star: require('@/assets/images/all/pc-comment-star1.png'),
          content: 'Thanks for your great customer service, be patient with profession and the quality is really beyond my expectation with this cost, highly recommend Orecto to you all who want to change the floor'
        },
        {
          img: require('@/assets/images/all/pc-comment-user8.png'),
          star: require('@/assets/images/all/pc-comment-star3.png'),
          content: 'In the beginning, we did some researches to know that Orecto has a long history in the line of PVC flooring and after reading the reviews we decide to have a try for the samples, the result is good and we start to order for big quantity gradually, after three year’s cooperation we’ve been now closely strategic partner'
        },
        {
          img: require('@/assets/images/all/pc-comment-user9.png'),
          star: require('@/assets/images/all/pc-comment-star1.png'),
          content: "We’re pleased to write the reviews since some of our users have given us positive evaluation on the stable quality, beautiful colors. One of the family are very happy with the whole installation effects especially easy to install compared to the previous floor"
        },
      ],
      isFirstComment: 1,
      productIconList: [
        {
          img: require('@/assets/images/all/pc-icon1301.png'),
          title: 'Fashion diversity',
        },
        {
          img: require('@/assets/images/all/pc-icon1302.png'),
          title: 'Strong Acoustic insulation',
        },
        {
          img: require('@/assets/images/all/pc-icon1303.png'),
          title: 'Suitable for floor heating',
        },
        {
          img: require('@/assets/images/all/pc-icon1304.png'),
          title: 'Flame retardant',
        },
        {
          img: require('@/assets/images/all/pc-icon1305.png'),
          title: 'Super anti-skid',
        },
        {
          img: require('@/assets/images/all/pc-icon1306.png'),
          title: 'Stain resistant',
        },
        {
          img: require('@/assets/images/all/pc-icon1307.png'),
          title: 'Wear and Water resistant',
        },
        {
          img: require('@/assets/images/all/pc-icon1308.png'),
          title: 'Eco-Friendly',
        },

      ],
      bannerList: [
        {
          img: require('@/assets/images/all/pc-banner2.jpg')
        },
        {
          img: require('@/assets/images/all/pc-banner1.png')
        },

        {
          img: require('@/assets/images/all/pc-banner3.png')
        }
      ],
      topList: [
        {
          img: require('@/assets/images/all/pc-top1.png')
        },
        {
          img: require('@/assets/images/all/pc-top2.png')
        },
        {
          img: require('@/assets/images/all/pc-top3.png')
        }
      ],
      bottomList: [
        {
          img: require('@/assets/images/all/pc-bottom1.png')
        },
        {
          img: require('@/assets/images/all/pc-bottom2.png')
        },
        {
          img: require('@/assets/images/all/pc-bottom3.png')
        }
      ],
      caseList: [],
      formData: {
        name: '',
        email: '',
        content: ''
      },
      mySwiper: null,
      show: true
    };
  },
  computed: {
    ...mapState(["onlineUrl"]),
    finalCaseList() {
      return this.caseList.slice(0, 3);
    },
    finalCommentList() {
      let list = this.commentList.slice(0, 3);
      if (this.isFirstComment === 2) {
        list = this.commentList.slice(3, 6);
      }
      if (this.isFirstComment === 3) {
        list = this.commentList.slice(6, 9);
      }
      return list;
    }
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      await this.getCaseListFun();
      this.mySwiper = new Swiper("#swiper1", {
        direction: "horizontal",
        speed: 600,
        loop: true,
        autoplay: false,
        observer: true,
        observeParents: true,
        effect: "coverflow",
        slidesPerView: "auto",
        centeredSlides: true,
        coverflowEffect: {
          rotate: 0, //slide做3d旋转时Y轴的旋转角度。默认50。
          stretch: -40, //每个slide之间的拉伸值，越大slide靠得越紧。 默认0。
          depth: 50, //slide的位置深度。值越大z轴距离越远，看起来越小。 默认100。
          modifier: 5,
          slideShadows: false, //开启slide阴影。默认 true。
        },
      });
      setTimeout(() => {
        this.pxToRemFun();
      }, 500);
    },
    commentNext(type = 'down') {
      if (type === 'down') {
        if (this.isFirstComment > 1) {
          this.isFirstComment--;
        } else {
          this.isFirstComment = 3;
        }
      } else {
        if (this.isFirstComment < 3) {
          this.isFirstComment++;
        } else {
          this.isFirstComment = 1;
        }
      }
    },
    pxToRemFun() {
      const swiperSlideDoms = document.querySelectorAll('.swiper-slide');
      swiperSlideDoms.forEach(item => {
        const cssTransform = item.style.transform;
        const rootFontSize = 100;
        const remTransform = cssTransform.replace(/-?\d*\.?\d+px/g, match => {
          const pixelValue = parseFloat(match);
          const remValue = pixelValue / rootFontSize + 'rem';
          return remValue;
        });
        item.style.transform = remTransform;
      });
    },
    async getCaseListFun() {
      const res = await getCaseListApi();
      this.caseList = res?.data?.data || [];
    },
    changeImg(arrow = 'right') {
      if (arrow === 'right') {
        this.mySwiper.slideNext();
        this.pxToRemFun();
      } else {
        this.mySwiper.slidePrev();
        this.pxToRemFun();

      }
    },
    async submitForm() {
      const res = await contactUsApi(this.formData);
      this.$message.success('success');
      this.formData = this.$options.data().formData;
    },
  }
}
</script>

<style lang="scss">
//低版本引入css
@import "../../../node_modules/swiper/css/swiper.css";

.view-home {
  .common-icon-title {
    font-size: 0.5rem;
    position: relative;
    font-family: Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 900;
    &::after {
      content: "";
      display: block;
      width: 0.4rem;
      height: 0.15rem;
      background: rgba(161, 179, 173, 1);
      position: absolute;
      left: -0.14rem;
      top: 0.04rem;
      z-index: -1;
    }
  }
  .common-more {
    font-size: 0.14rem;
    line-height: 0.22rem;
    width: fit-content;
    padding: 0.06rem 0.27rem;
    background: rgba(0, 134, 86, 1);
    border-radius: 0.05rem;
    color: #fff;
  }
  .banner-box {
    .banner-list {
      height: 7rem;
      .el-carousel__container {
        height: 100%;
        .el-carousel__arrow--left {
          @include pcurl("pc-banner-arrow.png");
          transform: rotate(180deg);
          i {
            display: none;
          }
        }
        .el-carousel__arrow--right {
          @include pcurl("pc-banner-arrow.png");
          i {
            display: none;
          }
        }
      }
      .el-carousel__indicators {
        display: flex;
        align-items: center;
        justify-content: center;
        bottom: 0.38rem;
      }
      .el-carousel__indicator {
        display: flex;
        align-items: center;
        justify-content: center;
        .el-carousel__button {
          width: 0.24rem;
          height: 0.24rem;
          border-radius: 50%;
          opacity: 1;
          background: unset;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .el-carousel__button::after {
          content: "";
          display: block;
          width: 60%;
          height: 60%;
          border-radius: 50%;
          background: rgba(175, 175, 175, 1);
          border: 0.03rem solid unset;
        }
      }
      .is-active {
        .el-carousel__button {
          background: #fff;
        }
        .el-carousel__button::after {
          content: "";
          display: block;
          width: 60%;
          height: 60%;
          border-radius: 50%;
          background: #fff;
          border: 0.03rem solid rgba(217, 217, 217, 1);
        }
      }
    }
  }
  .main-box1 {
    width: 100%;
    height: 2.45rem;
    padding: 0 3.1rem;
    box-sizing: border-box;
    .box1-center {
      width: 100%;
      height: 1.45rem;
      padding: 0.17rem 0;
      box-sizing: border-box;
      box-shadow: 0rem 0rem 0.12rem 0 rgba(0, 0, 0, 0.2);
      position: relative;
      top: -0.24rem;
      z-index: 9;
      background: #fff;
      .box1-item {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        flex-grow: 1;
        border-right: 0.04rem solid rgba(242, 242, 242, 1);
        .box1-item-title {
          font-size: 0.65rem;
          font-weight: 600;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          span:nth-of-type(2) {
            margin-top: -0.1rem;
            margin-left: 0.1rem;
          }
        }
        .box1-item-text {
          font-size: 0.24rem;
        }
      }
      .box1-item:last-of-type {
        border: 0;
      }
    }
  }
  .about-us-box {
    width: 100%;
    height: 3.91rem;
    @include pcurl("pc-icon8.png");
    padding: 1.03rem 3rem;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    .left-box {
      width: 1.92rem;
      height: 1.92rem;
      color: #fff;
      font-size: 0.5rem;
      font-family: Tahoma, Geneva, Verdana, sans-serif;
      font-weight: 900;
      margin-right: 0.2rem;
      flex-shrink: 0;
    }
    .right-box {
      position: relative;
      font-size: 0.22rem;
      line-height: 0.3rem;
      color: #fff;
      font-weight: 300;
      font-family: PingFang SC, PingFang SC;
      .more-box {
        position: absolute;
        right: 0;
        bottom: -0.5rem;
      }
    }
  }
  .main-box2 {
    width: 100%;
    padding: 1.25rem 3.1rem;
    box-sizing: border-box;
    .box2-title {
      text-align: center;
      font-size: 0.5rem;
      margin-bottom: 0.64rem;
    }
    .box2-item-list {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      .box2-item {
        width: 35%;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        text-align: center;
        margin-bottom: 0.43rem;
        padding: 0 0.1rem;
        box-sizing: border-box;
        .item-img {
          width: 0.82rem;
          height: 0.82rem;
          margin-bottom: 0.27rem;
        }
        .item-title {
          width: 100%;
          margin-bottom: 0.1rem;
          font-size: 0.2rem;
          font-weight: 600;
        }
        .item-text {
          width: 100%;
          font-size: 0.18rem;
          font-weight: 300;
        }
      }
      .box2-item-width {
        width: 30%;
      }
    }
  }
  .main-box3 {
    width: 100%;
    height: 5.01rem;
    @include pcurl("pc-icon10.png");
    padding: 0 4.55rem;
    box-sizing: border-box;
    margin-bottom: 1.32rem;
    .box-3-title {
      text-align: center;
      font-size: 0.5rem;
      color: #fff;
      font-weight: 600;
    }
  }
  .main-box4 {
    padding: 0 3.36rem;
    box-sizing: border-box;
    .box4-block1 {
      margin-bottom: 1.25rem;

      .left-text {
        font-size: 0.18rem;
        width: 5.8rem;
        flex-shrink: 0;
      }
      .right-img {
        width: 4.1rem;
        flex-shrink: 0;
      }
    }
    .box4-block2 {
      margin-bottom: 1.25rem;
      .title-box {
        margin-bottom: 0.6rem;
      }
      .comment-box {
        width: 100%;
        position: relative;
        .home-bg1 {
          position: absolute;
          left: 2rem;
          top: -0.5rem;
          width: 8.44rem;
          height: 6.58rem;
          @include pcurl("pc-home-bg1.png");
        }
        .top-comment-box {
          .comment-item {
            margin-bottom: 0.7rem;
            color: #fff;
            .header-img {
              width: 1.3rem;
              flex-shrink: 0;
              margin-right: 0.52rem;
            }
            .comment-text {
              width: 100%;
              @include pcurl("pc-icon15.png");
              padding: 0.29rem 0.44rem 0.29rem 0.64rem;
              position: relative;
              .text {
                // transform: scaleX(-1);
                min-height: 0.86rem;
                font-size: 0.2rem;
              }
              .star {
                height: 0.24rem;
                display: flex;
                justify-content: flex-end;
                position: absolute;
                right: 0.63rem;
                bottom: 0.14rem;
                img {
                  transform: scaleX(-1);
                }
              }
            }
          }
          .comment-item-right {
            transform: scaleX(-1);
            .comment-text {
              .text {
                transform: scaleX(-1);
              }
              .star {
                justify-content: flex-start;
                left: 0.8rem;
              }
            }
          }
        }
        .page-box {
          .pages {
            .page-left {
              width: 0.5rem;
              height: 0.5rem;
              border-radius: 50%;
              margin-right: 0.4rem;
              @include pcurl("pc-icon17.png");
              transform: translateY(-50%) rotate(0deg);
            }
            .page-left:hover {
              @include pcurl("pc-icon18.png");
              transform: translateY(-50%) rotate(60deg);
            }
            .page-right {
              width: 0.5rem;
              height: 0.5rem;
              border-radius: 50%;
              @include pcurl("pc-icon17.png");
              transform: translateY(-50%) rotate(-60deg);
            }
            .page-right:hover {
              @include pcurl("pc-icon18.png");
              transform: translateY(-50%) rotate(0deg);
            }
          }
        }
      }
    }
    .box4-block3 {
      margin-bottom: 1rem;
      position: relative;
      .home-bg2 {
        position: absolute;
        z-index: -1;
        left: 0rem;
        top: 0rem;
        width: 13.36rem;
        height: 8.96rem;
        @include pcurl("pc-home-bg2.png");
      }
      .block3-top {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row-reverse;
        margin-bottom: 0.6rem;
        .top-item {
          width: 2.76rem;
          margin-right: 0.4rem;
        }
        .block3-top-title {
          max-width: 2.3rem;
          margin-right: auto;
        }
      }
      .block3-bottom {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        .bottom-item {
          width: 2.76rem;
          margin-right: 0.4rem;
          flex-shrink: 0;
        }
        .more-box {
          width: 2.76rem;
          flex-shrink: 0;
          display: flex;
          align-content: center;
          justify-content: flex-end;
          padding-bottom: 0.1rem;
          padding-right: 1rem;
        }
      }
    }
    .box4-block4 {
      flex-wrap: wrap;
      margin-bottom: 0.25rem;
      width: 14.4rem;
      transform: translateX(-1.2rem);
      .block4-item {
        width: 25%;
        flex-shrink: 0;
        text-align: center;
        margin-bottom: 1rem;
        .item-img {
          margin-bottom: 0.26rem;
          .img {
            width: 0.84rem;
          }
        }
      }
    }
  }
  .main-box5 {
    margin-bottom: 0.5rem;
    .box5-title {
      margin-bottom: 0.6rem;
    }
    .box5-banner {
      margin-bottom: 0.6rem;
      .swiper-box {
        // 轮播图
        overflow: hidden;
        width: 100%;
        margin-bottom: 20px;

        .swiper-slide {
          width: 7.59rem;
          height: 4.36rem;
          font-size: 14px;
          text-align: center;
          line-height: 80px;
          border-radius: 8px;
          position: relative;
        }
        .swiper-slide-active {
        }

        .left-arrow {
          width: 0.5rem;
          height: 0.5rem;
          background: red;
          position: absolute;
          left: 5.04rem;
          top: 50%;
          z-index: 99;
          transform: translateY(-50%);
          @include pcurl("pc-icon17.png");
          transform: translateY(-50%) rotate(0deg);
        }
        .left-arrow:hover {
          @include pcurl("pc-icon18.png");
          transform: translateY(-50%) rotate(-60deg);
        }
        .right-arrow {
          width: 0.5rem;
          height: 0.5rem;
          background: red;
          position: absolute;
          right: 5.04rem;
          z-index: 99;
          top: 50%;
          transform: translateY(-50%) rotate(60deg);
          @include pcurl("pc-icon17.png");
        }
        .right-arrow:hover {
          @include pcurl("pc-icon18.png");
          transform: translateY(-50%) rotate(0deg);
        }
      }
      // .box5-banner-item {
      //   .el-carousel__container {
      //     height: 100%;
      //     .el-carousel__arrow--left {
      //       @include pcurl("pc-banner-arrow.png");
      //       transform: rotate(180deg);
      //       i {
      //         display: none;
      //       }
      //     }
      //     .el-carousel__arrow--right {
      //       @include pcurl("pc-banner-arrow.png");
      //       i {
      //         display: none;
      //       }
      //     }
      //   }
      //   .el-carousel__indicators {
      //     // display: flex;
      //     // align-items: center;
      //     // justify-content: center;
      //     // bottom: 0.38rem;
      //   }
      //   .el-carousel__indicator {
      //     .el-carousel__button {
      //       opacity: 0;
      //     }
      //   }
      // }
    }
  }
  .main-box6 {
    padding: 0 3.1rem 1.5rem 3.1rem;
    box-sizing: border-box;
    position: relative;
    align-items: flex-start;
    .home-bg3 {
      position: absolute;
      z-index: -2;
      right: 0.1rem;
      top: -1.14rem;
      width: 17.4rem;
      height: 7.67rem;
      @include pcurl("pc-home-bg3.png");
    }
    .home-bg4 {
      position: absolute;
      z-index: -1;
      left: 0rem;
      top: 0rem;
      width: 100%;
      height: 5.94rem;
      @include pcurl("pc-home-bg4.png");
    }
    .left-title-box {
      width: 2.11rem;
      margin-right: 1.63rem;
      flex-shrink: 0;
      padding-top: 0.46rem;
    }
    .right-form-box {
      padding: 0.64rem 0.92rem 0.2rem 0.92rem;
      box-sizing: border-box;
      box-shadow: 0rem 0rem 0.12rem rgba(0, 0, 0, 0.1);
      width: 9.14rem;
      flex-shrink: 0;
      background: #fff;
      margin-top: 0.1rem;
      margin-left: 0.1rem;
      .div1 {
        margin-bottom: 0.32rem;
        .input {
          width: 3.36rem;
          height: 0.42rem;
          // background: red;
          padding: 0 0.2rem;
          box-sizing: border-box;
          border: 0.02rem solid rgba(146, 183, 133, 1);
          flex-shrink: 0;
        }
      }
      .div2 {
        margin-bottom: 0.38rem;
        width: 100%;
        height: 1.73rem;
        background: #fff;
        margin-bottom: 0.25rem;
        padding: 0.06rem 0.16rem;
        box-sizing: border-box;
        font-size: 0.2rem;
        border: 0.02rem solid rgba(146, 183, 133, 1);
      }
      .div3 {
        .btn {
          padding: 0.07rem 0.5rem;
          background: rgba(0, 94, 60, 1);
          border-radius: 0.04rem;
          color: #fff;
          font-size: 0.24rem;
        }
      }
    }
  }
}
</style>